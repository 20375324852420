.Reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}

.left-r {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 1 1;
  grid-auto-rows: 1fr;
  gap: 1rem;
}
.left-r > img {
  object-fit: cover;
}
.left-r > :nth-child(1) {
  width: 12rem;
  object-fit: cover;
  grid-row: 1/3;
  height: 44rem;
}
.left-r > :nth-child(2) {
  width: 100%;
  height: 20rem;
  grid-column: 2/4;
  object-fit: cover;
}
.left-r > :nth-child(3) {
  width: 100%;
  grid-row: 2;
  grid-column: 2/3;
  height: 19rem;
}
.left-r > :nth-child(4) {
  width: 100%;
  grid-row: 2;
  grid-column: 3/4;
  height: 19rem;
}
.right-r {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.right-r > span {
  font-weight: bold;
  color: #f48915;
}
.right-r > div {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
.solutions p {
  font-size: 0.8rem;
  margin-top: -1rem;
  font-weight: 400;
}
.details-r {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.details-r > div {
  font-size: 1rem;
  gap: 1rem;
  margin-top: -1rem;
  display: flex;
}
.details-r > div > img {
  width: 3rem;
  height: 3rem;
}
.details-r > div > span > p {
  /* display: block;
  text-align: left; */
  font-weight: 200;
}

@media screen and (max-width: 786px) {
  .Reasons {
    flex-direction: column;
  }
  .left-r {
    grid-auto-rows: auto;
    overflow: hidden;
  } /*
  .left-r > :nth-child(1) {
    object-fit: contain;
    width: 17rem;
  }
  .left-r > :nth-child(2) {
    object-fit: contain;
    height: 10rem;
  }
  .left-r > :nth-child(3) {
    object-fit: contain;
  }
  .left-r > :nth-child(4) {
    object-fit: contain;
  } */
  .right-r > :nth-child(2) {
    display: flex;
    flex-direction: column;
  }
}
