/* HERO CSS */

.hero {
  display: flex;
  justify-content: space-between;
}

.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: #f48915;
}
.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.the-best-ad > span {
  z-index: 2;
}
.the-best-ad > div {
  position: absolute;
  background-color: orange;
  width: 5.4rem;
  height: 80%;
  left: 8px;
  z-index: 1;
  border-radius: 3rem;
}
.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1 5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-text > div:nth-of-type(2) {
  /* font-size: 1rem; */
  font-size: 1.5rem;

  font-weight: bold;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.hero-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.figures {
  display: flex;
  gap: 2rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
}
.figures > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.figures > div > span:nth-of-type(2) {
  color: #9c9c9c;
  text-transform: uppercase;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.hero-buttons > :nth-child(1) {
  color: white;
  background-color: #f48915;
  width: 8rem;
}
.hero-buttons > :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid #f48915;
}

/* right part of hero section */

.right-h > .btn {
  position: absolute;
  top: 0%;
  right: 3rem;
  top: 2rem;
  color: black;
}
.tracking-icon {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #464d53;
  width: fit-content;
  padding: 1rem 1.2rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}
.tracking-icon > img {
  width: 5rem;
  height: auto;
}
.tracking-icon > :nth-child(2) {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.hero-image {
  position: absolute;
  top: 10rem;
  right: 7rem;
  width: 29rem;
  filter: drop-shadow(2px 2px 1px gray);
  z-index: 2;
}
.hero-image-back {
  position: absolute;
  top: 13rem;
  right: 20rem;
  z-index: 1;
  width: 15rem;
}
.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
  top: 10rem;
}

@media screen and (max-width: 786px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur {
    width: 14rem;
  }
  .the-best-ad {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > :nth-child(3) > span {
    font-size: 0.7rem;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons {
    justify-content: center;
  }
  .figures > div > span:nth-child(1) {
    font-size: large;
  }
  .figures > div > span:nth-child(2) {
    font-size: small;
  }
  .right-h {
    position: relative;
    background: none;
  }
  .tracking-icon {
    left: 1rem;
    z-index: 1;
    top: 0rem;
  }
  /* .tracking-icon > img {
    width: 1.7rem;
    align-self: center;
  } */
  .hero-image {
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;
  }
  .hero-image-back {
    width: 10rem;
    left: 3rem;
    top: 1rem;
    z-index: 0;
    /* position: relative; */
  }
}
