/* HEADER CSS */

.header {
  display: flex;
  justify-content: space-between;
  color: white;
}
.logo {
  width: 10rem;
  height: auto;
  margin: -2rem -1rem;
}
.header-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
}
.header-menu > li {
  cursor: pointer;
  transition: 0.1s ease all;
}
.header-menu > li:hover {
  color: orange;
  /* font-size: 1.1rem; */
}
@media screen and (max-width: 786px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: #3c3f45;
    padding: 1rem;
    transition: 0.5s all;
  }
  .logo {
    width: 7rem;
    height: auto;
    margin: -2rem -2rem;
  }
}
ul {
  transition: 0.5s all;
}
